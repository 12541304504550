@import (reference) "theme/colors.less";
@import (reference) "variables.less";
@import (reference) "theme/variables.less";
body {
    // Button skin constructor.
    // Sets the skin/colour for the button.
    // If something does not need a variable, do not put it here. This is to prevent issues with the compiled CSS being too large for IE.

    .button-skin-constructor(@bg-color, @bg-hover-color, @border-color, @border-hover-color, @text-color, @text-hover-color) {
        background: @bg-color;
        border: 1px solid @border-color;
        border-radius: 3px;
        color: @text-color;
        font-weight: bold;
        font-size: @font-size-chat;

        @media (min-width: @breakpoint-tablet) {
            font-size: @font-size-landing;
        }

        &:hover {
            background: @bg-hover-color;
            color: @text-hover-color;
        }

        &:active {
            background: @bg-hover-color;
        }
    }

    // Skin specifications for different classes.
    // These should not be changed as they set the colour of buttons based on class.
    // Primary, Info, Success and Danger are changed for Freightlancer, everything else other than the default is unchanged

    .btn-primary {
        .primary-bt-skin();
    }

    .btn-info {
        .secondary-bt-skin();
    }



    // Skins.
    // Sets the exact colours of buttons from the variables file.
    // Be careful when changing values here as they will change the button colours. Only use variables here; do not use hex values.

    // .btn-primary (orange)
    .primary-bt-skin() {
        .button-skin-constructor(
            @primary-button-bg, // background color
            @primary-button-hover, // background hover color
            @primary-button-border, // border color
            @primary-button-border,  // border color hover
            @text-color-light, // text color
            @text-color-light  // text color hover
        );
    }

    // .btn-info (blue)
    .secondary-bt-skin() {
        .button-skin-constructor(
            @secondary-button-bg, // background color
            @secondary-button-hover, // background hover color
            @secondary-button-border, // border color
            @secondary-button-border,  // border color hover
            @text-color-light,  // text color
            @text-color-light   // text color hover
        );
    }

}
